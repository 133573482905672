import { motion } from "framer-motion";

import { EcosystemId, EcosystemLogo } from "~/components/logos/ecosystem";
import { ExternalLink } from "~/components/site/external-link";
import { container, item } from "~/components/site/layout";

type RPCPartner = Readonly<{
  ecosystemId: EcosystemId;
  href?: string;
  border?: string;
  bg?: string;
  lightGradient?: string;
  darkGradient?: string;
  className?: string;
}>;

const partners: RPCPartner[] = [
  {
    ecosystemId: "alchemy",
  },
  {
    ecosystemId: "caldera",
    href: "https://www.caldera.xyz/",
    border: "border-[#dce3ed] dark:border-[rgba(168,120,101,.3)]",
    bg: "bg-secondary dark:bg-[rgba(236,102,48,.3)]",
    lightGradient:
      "bg-[radial-gradient(75%_150%_at_center_top,rgba(236,102,48,.1),rgba(255,255,255,0))]",
    darkGradient:
      "dark:bg-[radial-gradient(75%_150%_at_center_top,rgba(168,120,101,.6),rgba(255,255,255,0))]",
  },
  {
    ecosystemId: "conduit",
    href: "https://www.conduit.xyz/",
    border: "border-[#dce3ed] dark:border-[rgba(122,158,158,.3)]",
    bg: "bg-secondary dark:bg-[rgba(37,169,168,.3)]",
    lightGradient:
      "bg-[radial-gradient(75%_150%_at_center_top,rgba(37,169,168,.1),rgba(255,255,255,0))]",
    darkGradient:
      "dark:bg-[radial-gradient(75%_150%_at_center_top,rgba(122,158,158,.6),rgba(255,255,255,0))]",
  },
];

export function RollupLogos(): JSX.Element {
  return (
    <motion.div
      variants={container}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      className="grid grid-cols-2 sm:flex lg:grid lg:grid-cols-2 items-center justify-center gap-6 max-w-[336px] sm:max-w-none lg:max-w-[448px] mx-auto"
    >
      {partners.map((p) =>
        p.ecosystemId === "alchemy" ? (
          <motion.div
            key={p.ecosystemId}
            variants={item}
            className="flex items-center justify-around col-span-2"
          >
            <EcosystemLogo ecosystemId={p.ecosystemId} className="h-5 lg:h-7" />
          </motion.div>
        ) : (
          <motion.div
            key={p.ecosystemId}
            variants={item}
            className={`${p.bg} border ${p.border} rounded-lg overflow-clip`}
          >
            <ExternalLink
              href={p.href}
              title={`Learn more about ${
                p.ecosystemId.charAt(0).toUpperCase() + p.ecosystemId.slice(1)
              }`}
              className={`flex items-center justify-around p-3 lg:p-4 ${p.lightGradient} ${p.darkGradient}`}
            >
              <EcosystemLogo
                ecosystemId={p.ecosystemId}
                className="h-5 lg:h-7"
              />
            </ExternalLink>
          </motion.div>
        )
      )}
    </motion.div>
  );
}
