import { motion } from "framer-motion";

import { EcosystemId, EcosystemLogo } from "~/components/logos/ecosystem";
import { container, item } from "~/components/site/layout";

const customers: EcosystemId[] = [
  "glo-dollar",
  "etherfuse",
  "digital-asset",
  "coinflow",
  "ethereum-classic",
  "rain",
  "perena",
];

export function CustomerLogos(): JSX.Element {
  return (
    <motion.div
      variants={container}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      className="flex flex-wrap justify-evenly lg:justify-between items-center mx-auto gap-y-8"
    >
      {customers.map((c, i) => (
        <motion.div
          key={i}
          variants={item}
          className="flex justify-center items-center w-1/2 sm:w-1/4 lg:w-auto"
        >
          <EcosystemLogo ecosystemId={c as EcosystemId} />
        </motion.div>
      ))}
    </motion.div>
  );
}
