import {
  faArrowUpBigSmall,
  faBolt,
  faShieldCheck,
  faSignalBars,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";
import { motion } from "framer-motion";

import { BlockchainIcon } from "~/components/logos/blockchain";
import { CTAPrimary } from "~/components/site/call-to-action-primary";
import { EcosystemCarousel } from "~/components/site/carousel";
import { CustomerLogos } from "~/components/site/customer-logos";
import { container, item, Layout } from "~/components/site/layout";
import { ModalVideo } from "~/components/site/modal-video";
import { Quote } from "~/components/site/quote";
import { RollupLogos } from "~/components/site/rollup-logos";
import { Settings, Testimonials } from "~/components/site/testimonials";
import { ROUTE_DATA } from "~/lib/constants";
import { BLOCKCHAINS } from "~/lib/ecosystem";
import { getMeta } from "~/lib/get-meta";
import { getUrls } from "~/lib/get-urls";
import type { Content } from "~/routes/about";

const { desc, path, title } = ROUTE_DATA.home;

const quotes: Quote[] = [
  {
    quote:
      "The value add that Brale brings is immeasurable. Their Stablecoin-as-a-Service approach enabled us to issue Glo Dollar in a regulatory compliant way in a matter of weeks rather than the 6+ month process of the competition. They'll do everything they can to issue, support, and grow your stablecoin. An awesome partner to work with!",
    author: "Garm Lucassen",
    photo: "/assets/photos/garm-lucassen.png",
    title: "CTO & Co-founder",
    company: "Glo Dollar",
    logo: "/assets/stablecoins/usdglo-logo.svg",
    url: "/stablecoins/USDGLO",
  },
  {
    quote:
      "Brale is streamlining the regulated stablecoin issuance space and we are excited to work with them in developing Classic USD (USC), Ethereum Classic's premiere regulated stablecoin. Brale facilitates the issuance, redemption, and treasury management of USC ensuring USC is redeemable 1:1 for U.S. dollars. This allows our team to focus on building composable DeFi protocols for the Ethereum Classic ecosystem.",
    author: "Christopher Mercer",
    photo: "/assets/photos/ethereum-classic-logo.svg",
    title: "Founder",
    company: "EthereumClassic.com",
    logo: "/assets/stablecoins/usc-logo.png",
    url: "/stablecoins/USC",
  },
  {
    quote:
      "The MXNe stablecoin, made possible through our partnership with Brale, has the potential to revolutionize the way we transact and capture value. This is a significant step forward, benefiting the communities who create, develop, promote, and use it. Brale is a genuinely legendary team, and we look forward to  creating a platform that allows anyone, anywhere in the world, to develop a Stablecoin in any currency.",
    author: "David Taylor",
    photo: "/assets/photos/david-taylor.png",
    title: "Founder & CEO",
    company: "Etherfuse",
    logo: "/assets/stablecoins/mxne-logo.png",
    url: "/stablecoins/MXNe",
  },
];

const settings: Settings = {
  adaptiveHeight: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 20000,
  dots: true,
  fade: true,
  infinite: true,
  draggable: true,
};

const benefits: Content[] = [
  {
    icon: faBolt,
    title: "Fast & multi-chain out of the box",
    text: "They say years, we say minutes. Deploy new multi-chain stablecoins at the speed of block confirmations. EVM and non-EVM chains are natively supported.",
  },
  {
    icon: faSignalBars,
    title: "Generate revenue",
    text: "We've built the first stablecoin issuance platform modeled after the tried and tested card issuance model that extends the benefits of issuance programs into protocols.",
  },

  {
    icon: faShieldCheck,
    title: "Regulated reserves",
    text: "We believe in merging the newest technology with the regulatory frameworks of today, to support the builders of tomorrow.",
  },
  {
    icon: faArrowUpBigSmall,
    title: "Community focused",
    text: "We focus on community impact, not market cap. If your project is a $10k cap project or a $10b cap project, we've got you covered.",
  },
];

export function loader({ request }: LoaderFunctionArgs) {
  return json({
    urls: getUrls({ path, request }),
  });
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return data ? getMeta({ desc, title, urls: data.urls }) : [];
};

export default function Home(): JSX.Element {
  return (
    <Layout>
      <section className="relative isolate overflow-clip">
        <div className="container">
          <div className="lg:grid lg:grid-cols-2 gap-16 lg:items-center section-first">
            <motion.div
              variants={item}
              className="mx-auto flex-shrink-0 lg:mx-0 lg:max-w-xl"
            >
              <h1 className="mb-12">Create your stablecoin today</h1>
              <div className="flex gap-4">
                <ModalVideo
                  video="/assets/site/brale-demo.mp4"
                  videoWidth={1920}
                  videoHeight={1080}
                />
                <a href="/pricing" className="btn secondary no-underline">
                  See pricing
                </a>
              </div>
            </motion.div>
            <motion.div
              variants={item}
              className="mx-auto mt-16 flex sm:mt-24 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none"
            >
              <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                <img
                  src="/assets/site/create-ysbc-stablecoin.gif"
                  alt="App screenshot"
                  width={2432}
                  height={1442}
                  className="w-[60rem] rounded-3xl border-8 border-primary"
                />
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      <section className="section-sub">
        <div className="container">
          <CustomerLogos />
        </div>
      </section>
      <section className="section-sub">
        <div className="container">
          <div className="lg:grid lg:grid-cols-2 items-start gap-16">
            <motion.div variants={item} className="lg:sticky top-8">
              <div className="eyebrow">Benefits</div>
              <h2 className="m-0 mb-6">
                The platform for creating stablecoins
              </h2>
              <p className="subheading mt-2 mb-12 lg:mb-0">
                Everything you need to launch a stablecoin you control and
                benefit from.
              </p>
            </motion.div>
            <motion.div variants={item} className="mt-12 lg:mt-16">
              <div className="flex flex-col col-span-2 gap-y-8">
                <motion.div
                  variants={container}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  className="flex flex-col sm:grid sm:grid-cols-2 blurb-gap"
                >
                  {benefits.map((c) => (
                    <motion.div variants={item} key={c.title}>
                      <FontAwesomeIcon
                        className="h-12 w-12 mb-3 text-purple-500"
                        icon={c.icon}
                      />
                      <h4 className="mt-0 mb-3">{c.title}</h4>
                      <p className="blurb mb-0">{c.text}</p>
                    </motion.div>
                  ))}
                  <div className="col-span-2">
                    <h4 className="mt-0 mb-4">Supported chains</h4>
                    <div className="flex flex-wrap gap-2 xl:gap-4">
                      {BLOCKCHAINS.map((b, i) => (
                        <BlockchainIcon
                          key={i}
                          className="h-8 w-8 lg:h-10 lg:w-10 xl:-mr-1 border border-primary rounded-full"
                          chainId={b.chainId}
                        />
                      ))}
                    </div>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      <section className="section-sub">
        <div className="container">
          <div className="lg:grid lg:grid-cols-2 items-center gap-16">
            <motion.div variants={item} className="mb-12 lg:mb-0">
              <div className="eyebrow">Ecosystem</div>
              <h2 className="m-0 mb-6">Launch with an ecosystem</h2>
              <p className="subheading mt-2 mb-12">
                Launch with day one integrations to support your stablecoin.
              </p>
              <a
                href="/ecosystem"
                className="btn secondary no-underline !inline-block"
              >
                Explore the ecosystem
              </a>
            </motion.div>
            <motion.div variants={item}>
              <EcosystemCarousel />
            </motion.div>
          </div>
        </div>
      </section>
      <section className="section-sub">
        <div className="container">
          <div className="lg:grid lg:grid-cols-2 items-center gap-16">
            <motion.div variants={item} className="mb-12 lg:mb-0">
              <h2 className="m-0 mb-6">Friendly with your rollup</h2>
              <p className="subheading mt-2 mb-12 md:mb-0">
                Brale integrates with your existing tools right out of the box,
                making it simple to issue a native stablecoin on any rollup.
              </p>
            </motion.div>
            <motion.div variants={item}>
              <RollupLogos />
            </motion.div>
          </div>
        </div>
      </section>
      <motion.div
        variants={item}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <Testimonials quotes={quotes} settings={settings} />
      </motion.div>
      <motion.div
        variants={item}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <CTAPrimary
          heading="Create a stablecoin. Together."
          subheading="Collaborate, plan, and build with us."
          primaryBtn="Talk to sales"
          primaryBtnUrl="/contact"
          secondaryBtn="View product"
          secondaryBtnUrl="/product"
        />
      </motion.div>
    </Layout>
  );
}
