// eslint-disable-next-line @typescript-eslint/no-var-requires
const Slider = require("react-slick").default;

import type { Quote } from "~/components/site/quote";

export type Settings = Readonly<{
  adaptiveHeight?: boolean;
  arrows?: boolean;
  autoplay?: boolean;
  autoplaySpeed?: number;
  dots?: boolean;
  draggable?: boolean;
  fade?: boolean;
  infinite?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
  speed?: number;
}>;

type Props = Readonly<{
  quotes: Quote[];
  settings: Settings;
}>;

export function Testimonials({ quotes, settings }: Props): JSX.Element {
  return (
    <section className="section-sub">
      <div className="container">
        <Slider {...settings} className="testimonials">
          {quotes.map((quote) => (
            <div key={quote.author}>
              <h3 className="quote">&ldquo;{quote.quote}&rdquo;</h3>
              <div className="flex items-center gap-6">
                <img
                  alt={quote.author}
                  src={quote.photo}
                  className="h-24 w-24 rounded-full"
                />
                <div>
                  <div className="eyebrow text-primary !mb-0">
                    {quote.author}
                  </div>
                  <span>{quote.title}, </span>
                  <span className="whitespace-nowrap">{quote.company}</span>
                </div>
                {quote.logo && (
                  <div className="hidden sm:block border-l border-primary pl-6 py-2">
                    <a href={quote.url}>
                      <img
                        alt={`${quote.company} logo`}
                        src={quote.logo}
                        className="h-12 w-12 hover:scale-110 duration-300 hover:ease-in-out"
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
